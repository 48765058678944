import { parse } from "vue-currency-input";

export default {
  data() {
    return {
      filterInvoiceStatus: "",
    };
  },
  methods: {
    newContractList(contracstList) {
      let newList = [];
      if (contracstList !== undefined)
        contracstList.forEach((contract) => {
          const paymentMethods = contract.payment_methods
            ? JSON.parse(contract.payment_methods)
            : [];
          const creditCardRows = contract.meta.credit_card_rows
            ? JSON.parse(contract.meta.credit_card_rows)
            : [];
          const paymentCardRows = contract.meta.payment_card_rows
            ? JSON.parse(contract.meta.payment_card_rows)
            : [];
          const paymentLinkRows = contract.meta.payment_link_rows
            ? JSON.parse(contract.meta.payment_link_rows)
            : [];

          if (["Serviços", "Reserva Online"].includes(contract.package_type)) {
            JSON.parse(contract.contracted_services).forEach((product) => {
              if (product === "hotel") {
                JSON.parse(contract.meta.hotel_rows).forEach((hotel) => {
                  newList = [...newList, ...this.hotelEntries(contract, hotel)];
                });
              }

              if (product === "service") {
                JSON.parse(contract.meta.service_rows).forEach((service) => {
                  newList = [
                    ...newList,
                    ...this.serviceEntries(contract, service),
                  ];
                });
              }

              if (product === "flight") {
                JSON.parse(contract.meta.flight_sections_rows).forEach(
                  (flight) => {
                    newList = [
                      ...newList,
                      ...this.flightEntries(
                        contract,
                        flight,
                        paymentMethods,
                        creditCardRows,
                        paymentLinkRows,
                        paymentCardRows
                      ),
                    ];
                  }
                );
              }
            });
          } else {
            newList = [...newList, ...this.packageEntries(contract)];
          }
        });

      return newList;
    },
    hotelEntries(contract, hotel) {
      const { totalIncentives } = this.getHotelTotalIncentives(contract, hotel);
      const { totalExtraTaxes } = this.getHotelTotalExtraTaxes(contract, hotel);
      const { allContractTravellers } = this.getAllContractTravellers(contract);

      const passengersArr = [];
      const locator = contract.meta[`hotel_${hotel.id}_locator`] ?? "---";
      const totalValuePerPax = parse(
        contract.meta[`hotel_${hotel.id}_total_price`]
      );
      const totalNetValuePerPax = parse(
        contract.meta[`contract_finances_hotel_${hotel.id}_net_value`]
      );
      const totalProfitPerPax = totalValuePerPax - totalNetValuePerPax;
      totalProfitPerPax;
      const travellersNameArr = [];

      const toPayValueDB =
        contract.meta[`contract_finances_hotel_${hotel.id}_to_pay_value`];

      const toPayValue = !["", 0, "0", undefined, "undefined"].includes(
        toPayValueDB
      )
        ? toPayValueDB
        : totalNetValuePerPax;

      hotel.accommodation.forEach((accommodation) => {
        accommodation.travellers.forEach((traveller) => {
          travellersNameArr.push(
            this.travellerNameById(
              allContractTravellers,
              contract.meta[
                `hotel_${hotel.id}_accommodation_${accommodation.id}_traveller_${traveller.id}`
              ]
            )
          );
        });
      });

      const entry = {
        key: `${contract.id}-${contract.sale}-hotel-${hotel.id}-${locator}`,
        id: contract.id,
        sale_id: contract.sale,
        release_date: contract.release_date,
        product_type: "hotel",
        package_type: contract.package_type,
        product_id: hotel.id,
        product: contract.meta[`hotel_${hotel.id}_name`],
        traveller_id: "",
        traveller_name: travellersNameArr.join("<br>"),
        locator,
        supplier: contract.meta[`contract_finances_hotel_${hotel.id}_supplier`],
        customer: contract.customer,
        company_branch: contract.company_branch,
        ticket_value: totalNetValuePerPax,
        ticket_tax_value: 0,
        extra_fee_value:
          contract.meta[
            `contract_finances_hotel_${hotel.id}_extra_fee_value`
          ] ?? 0,
        paid_at:
          contract.meta[`contract_finances_hotel_${hotel.id}_paid_at`] ?? "---",
        payment_type:
          contract.meta[`contract_finances_hotel_${hotel.id}_payment_type`] ??
          "---",
        audit_status:
          contract.meta[`contract_finances_hotel_${hotel.id}_audit_status`] ??
          "---",
        invoice_status:
          contract.meta[`contract_finances_hotel_${hotel.id}_invoice_status`] ??
          "Em aberto",
        invoice_group:
          contract.meta[`contract_finances_hotel_${hotel.id}_invoice_group`] ??
          "",
        invoice_id:
          contract.meta[`contract_finances_hotel_${hotel.id}_invoice_id`] ?? 0,
        event_id:
          contract.meta[`contract_finances_hotel_${hotel.id}_event_id`] ?? 0,
        incomings: totalIncentives ,
        to_pay: toPayValue ?? totalExtraTaxes,
        balance:
          contract.meta[`contract_finances_hotel_${hotel.id}_balance_value`] ??
          0,
      };

      passengersArr.push(entry);

      return passengersArr;
    },
    serviceEntries(contract, service) {
      const { totalIncentives } = this.getServiceTotalIncentives(
        contract,
        service
      );
      const { totalExtraTaxes } = this.getServiceTotalExtraTaxes(
        contract,
        service
      );
      const { allContractTravellers } = this.getAllContractTravellers(contract);

      const passengersArr = [];
      const locator = contract.meta[`service_${service.id}_locator`] ?? "---";
      const totalValuePerPax = parse(
        contract.meta[`service_${service.id}_total_price`]
      );
      const totalNetValuePerPax = parse(
        contract.meta[`contract_finances_service_${service.id}_net_value`]
      );
      const totalProfitPerPax = totalValuePerPax - totalNetValuePerPax;
      totalProfitPerPax;
      const travellersNameArr = [];


      const toPayValueDB =
        contract.meta[`contract_finances_service_${service.id}_to_pay_value`];

      const toPayValue = !["", 0, "0", undefined, "undefined"].includes(
        toPayValueDB
      )
        ? toPayValueDB
        : totalNetValuePerPax;

      service.travellers.forEach((traveller) => {
        travellersNameArr.push(
          this.travellerNameById(
            allContractTravellers,
            contract.meta[`service_${service.id}_traveller_${traveller.id}`]
          )
        );
      });

      const entry = {
        key: `${contract.id}-${contract.sale}-service-${service.id}-${locator}`,
        id: contract.id,
        sale_id: contract.sale,
        release_date: contract.release_date,
        product_type: "service",
        package_type: contract.package_type,
        product_id: service.id,
        product: contract.meta[`service_${service.id}_name`],
        traveller_id: "",
        traveller_name: travellersNameArr.join("<br>"),
        locator,
        supplier:
          contract.meta[`contract_finances_service_${service.id}_supplier`],
        customer: contract.customer,
        company_branch: contract.company_branch,
        ticket_value: totalNetValuePerPax,
        ticket_tax_value: 0,
        extra_fee_value:
          contract.meta[
            `contract_finances_service_${service.id}_extra_fee_value`
          ] ?? 0,
        paid_at:
          contract.meta[`contract_finances_service_${service.id}_paid_at`] ??
          "---",
        payment_type:
          contract.meta[
            `contract_finances_service_${service.id}_payment_type`
          ] ?? "---",
        audit_status:
          contract.meta[
            `contract_finances_service_${service.id}_audit_status`
          ] ?? "---",
        invoice_status:
          contract.meta[
            `contract_finances_service_${service.id}_invoice_status`
          ] ?? "Em aberto",
        invoice_group:
          contract.meta[
            `contract_finances_service_${service.id}_invoice_group`
          ] ?? "",
        invoice_id:
          contract.meta[`contract_finances_service_${service.id}_invoice_id`] ??
          0,
        event_id:
          contract.meta[`contract_finances_service_${service.id}_event_id`] ??
          0,
        incomings: totalIncentives ,
        to_pay: toPayValue ?? totalExtraTaxes,
        balance:
          contract.meta[
            `contract_finances_service_${service.id}_balance_value`
          ] ?? 0,
      };

      passengersArr.push(entry);

      return passengersArr;
    },
    flightEntries(
      contract,
      flight,
      paymentMethods,
      creditCardRows,
      paymentLinkRows,
      paymentCardRows
    ) {
      const passengersArr = [];
      const { allContractTravellers } = this.getAllContractTravellers(contract);

      let { totalCreditCards, paymentDirectWithSupplier } =
        this.getTotalCreditCards(contract, creditCardRows);

      let { totalPaymentCards } = this.getTotalPaymentCards(
        contract,
        paymentCardRows
      );

      let { flightTotalValue, flightTotalValueWithRule, tebPlusDuValue } =
        this.getFlightTotalValue(
          contract,
          flight,
          paymentMethods,
          paymentDirectWithSupplier
        );

      let { totalPaymentLink } = this.getTotalPaymentLinks(
        contract,
        paymentLinkRows
      );

      let { totalIncentives } = this.getFlightTotalIncentives(contract, flight);
      let { totalExtraTaxes } = this.getFlightTotalExtraTaxes(contract, flight);
      let { locatorTravellersArr } = this.getTravellerLocator(
        contract,
        flight,
        creditCardRows
      );

      let { bankTransferDividedByTravellers, totalBankTransfer } =
        this.getTotalBankTransfer(
          contract,
          paymentMethods,
          allContractTravellers
        );

      let flightValueIsEqualPaymentValues = false;

      if (totalBankTransfer == flightTotalValue.toFixed(2)) {
        flightValueIsEqualPaymentValues = true;
      }

      let productName = [];

      if (contract.meta[`flight_${flight.id}_type`] === "Regular") {
        flight.sections.forEach((section) => {
          productName.push(
            `${
              contract.meta[`flight_${flight.id}_section_${section.id}_origin`]
            } ⇒ ${
              contract.meta[
                `flight_${flight.id}_section_${section.id}_destination`
              ]
            }`
          );
        });

        flight.travellers.forEach((traveller) => {
          let { totalCreditCardsByLocator } = this.getTotalCreditCardsByLocator(
            contract,
            contract.meta[
              `flight_${flight.id}_traveller_${traveller.id}_locator`
            ],
            locatorTravellersArr,
            creditCardRows
          );

          totalCreditCardsByLocator;

          let {
            travellerValue,
            travellerTaxValue,
            travellerDuTaxValue,
            travellerTebAgencyValue,
          } = this.getTravellerTotals(contract, flight, traveller);

          if (totalCreditCards >= flightTotalValueWithRule)
            bankTransferDividedByTravellers = 0;

          const {
            incomingValue,
            toPayValue,
            balanceValue,
            theAuditStatus,
            theInvoiceStatus,
            theInvoiceGroup,
            locator,
            extra_fee_value,
            paid_at,
            payment_type,
            traveller_name,
            invoice_id,
            event_id,
          } = this.getValuesFromDataBase(
            contract,
            flight,
            traveller,
            allContractTravellers
          );

          // Regras de negocio
          let { totalIncomings, totalToPay } =
            this.flightValuesRulesByTraveller(
              contract,
              flight,
              paymentMethods,
              totalCreditCards,
              flightTotalValue,
              flightTotalValueWithRule,
              totalIncentives,
              travellerValue,
              travellerTaxValue,
              travellerDuTaxValue,
              travellerTebAgencyValue,
              tebPlusDuValue,
              incomingValue,
              paymentDirectWithSupplier,
              bankTransferDividedByTravellers,
              totalPaymentLink,
              totalExtraTaxes,
              toPayValue,
              totalBankTransfer,
              totalPaymentCards
            );

          const flightRow = {
            key: `${contract.id}-${contract.sale}-flight-${flight.id}-${traveller.id}-${locator}`,
            id: contract.id,
            sale_id: contract.sale ?? contract.sale_id,
            release_date: contract.release_date,
            package_type: contract.package_type,
            product_type: "flight",
            product_id: flight.id,
            product: productName.join(" | "),
            traveller_id: traveller.id,
            traveller_name,
            locator,
            supplier:
              contract.meta[`contract_finances_flight_${flight.id}_supplier`],
            customer: contract.customer,
            company_branch: contract.company_branch,
            value: travellerValue,
            ticket_value: travellerValue,
            ticket_tax_value: travellerTaxValue,
            extra_fee_value,
            paid_at,
            payment_type,
            audit_status: theAuditStatus,
            invoice_status: theInvoiceStatus,
            invoice_group: theInvoiceGroup,
            invoice_id,
            event_id,
            incomings: totalIncomings,
            to_pay: totalToPay,
            balance: balanceValue !== undefined ? parse(balanceValue) : 0,
            paymentDirectWithSupplier,
            flightValueIsEqualPaymentValues,
          };

          if (this.filterInvoiceStatus) {
            if (this.filterInvoiceStatus == theInvoiceStatus)
              passengersArr.push(flightRow);
          } else {
            passengersArr.push(flightRow);
          }
        });
      }

      return passengersArr;
    },
    flightValuesRulesByTraveller(
      contract,
      flight,
      paymentMethods,
      totalCreditCards,
      flightTotalValue,
      flightTotalValueWithRule,
      totalIncentives,
      travellerValue,
      travellerTaxValue,
      travellerDuTaxValue,
      travellerTebAgencyValue,
      tebPlusDuValue,
      incomingValue,
      paymentDirectWithSupplier,
      bankTransferDividedByTravellers,
      totalPaymentLink,
      totalExtraTaxes,
      toPayValue,
      totalBankTransfer,
      totalPaymentCards
    ) {
      let totalIncomings = 0;
      let totalToPayCalc = 0;
      let totalPaymentLinks = totalPaymentLink / flight.travellers.length;
      let totalToPay = 0;

      const logs = {
        flight: flight.id,
        name: "Table Rules",
        sale: contract.sale,
        totalIncentives,
        travellers: flight.travellers.length,
        directSupplier: paymentDirectWithSupplier,
        isBankTransfer: paymentMethods.includes("bank-transfer"),
        "credit >= flight?": totalCreditCards >= flightTotalValue.toFixed(2),
        totalCreditCards,
        flightTotalValue,
        flightTotalValueWithRule,
        tebPlusDuValue,
        totalBankTransfer,
        "bTransfer/travellers": bankTransferDividedByTravellers,
        totalPaymentLinks,
      };
      console.table(logs);

      if (![undefined, 0, "0,00", "", 0.0].includes(incomingValue)) {
        totalIncomings = parse(incomingValue);
      } else {
        totalIncomings = totalIncentives / flight.travellers.length;

        if (
          (paymentDirectWithSupplier &&
            paymentMethods.includes("bank-transfer") &&
            totalCreditCards >= flightTotalValue.toFixed(2)) ||
          (paymentDirectWithSupplier &&
            totalCreditCards >= flightTotalValue.toFixed(2))
        ) {
          totalIncomings += travellerDuTaxValue + travellerTebAgencyValue;
        }
      }

      if (bankTransferDividedByTravellers === 0 && totalPaymentLinks !== 0) {
        console.log(contract.sale, "rule 00");
        totalToPayCalc =
          totalPaymentLink / flight.travellers.length -
          totalIncomings -
          totalExtraTaxes -
          (travellerDuTaxValue + travellerTebAgencyValue);
      }

      if (
        totalPaymentLinks === 0 &&
        bankTransferDividedByTravellers !== 0 &&
        totalCreditCards === 0
      ) {
        console.log(
          contract.sale,
          "rule 01 flight",
          flight.id,
          totalIncomings,
          totalExtraTaxes,
          travellerDuTaxValue + travellerTebAgencyValue
        );
        totalToPayCalc =
          travellerValue + travellerTaxValue - totalIncomings - totalExtraTaxes;

        console.log(
          contract.sale,
          "rule 01 after flight",
          flight.id,
          totalToPayCalc
        );
      }

      if (
        totalPaymentLinks === 0 &&
        bankTransferDividedByTravellers !== 0 &&
        totalCreditCards !== 0
      ) {
        console.log(
          contract.sale,
          "rule 01.1 flight",
          flight.id,
          totalIncomings,
          totalExtraTaxes,
          travellerDuTaxValue + travellerTebAgencyValue,
          totalCreditCards / flight.travellers.length
        );
        totalToPayCalc =
          travellerValue +
          travellerTaxValue +
          travellerDuTaxValue +
          travellerTebAgencyValue;
        totalToPayCalc -= totalIncomings + totalExtraTaxes;
        totalToPayCalc -= travellerDuTaxValue + travellerTebAgencyValue;
        totalToPayCalc -= totalCreditCards / flight.travellers.length;

        console.log(
          contract.sale,
          "rule 01.1 after flight",
          flight.id,
          totalToPayCalc
        );
      }

      if (totalPaymentLinks === 0 && bankTransferDividedByTravellers === 0) {
        console.log(contract.sale, "rule 02");
        if (paymentDirectWithSupplier) {
          totalToPayCalc = totalExtraTaxes;
        } else {
          totalToPayCalc = totalIncomings - totalExtraTaxes;
        }
      }

      if (totalPaymentLinks !== 0 && bankTransferDividedByTravellers !== 0) {
        console.log(contract.sale, "rule 03");
        totalToPayCalc =
          travellerValue +
          travellerTaxValue -
          totalPaymentLinks -
          totalIncomings -
          totalExtraTaxes;
      }

      if (totalPaymentCards !== 0 && totalCreditCards === 0) {
        totalToPayCalc =
          flightTotalValue - (travellerDuTaxValue + travellerTebAgencyValue);
      }

      if (
        !paymentDirectWithSupplier &&
        totalCreditCards !== 0 &&
        bankTransferDividedByTravellers === 0
      ) {
        totalToPayCalc = travellerValue + travellerTaxValue;
        totalToPayCalc -= totalIncomings + totalExtraTaxes;
      }

      if (![undefined, 0, "0,00", "", 0.0].includes(toPayValue)) {
        totalToPay = parse(toPayValue);
      } else {
        totalToPay = totalToPayCalc;
      }

      if (
        paymentMethods.includes("payment-card") &&
        !["credit-card", "bank-transfer", "payment-link"].includes(
          paymentMethods
        )
      ) {
        totalIncomings += travellerDuTaxValue + travellerTebAgencyValue;
      }

      if (
        bankTransferDividedByTravellers === 0 &&
        paymentMethods.includes("bank-transfer") &&
        totalCreditCards < flightTotalValue.toFixed(2) &&
        paymentDirectWithSupplier
      )
        totalIncomings =
          totalIncomings - totalBankTransfer / flight.travellers.length;

      return { totalIncomings, totalToPay };
    },
    travellerNameById(travellers, id) {
      let theTraveller = "";

      if (travellers)
        travellers.forEach((traveller) => {
          if (traveller.id == id) theTraveller = traveller.name;
        });

      return theTraveller;
    },
    getTotalPaymentLinks(contract, paymentLinkRows) {
      let totalPaymentLink = 0;

      if (paymentLinkRows.length) {
        paymentLinkRows.forEach(({ id }) => {
          totalPaymentLink += parse(
            contract.meta[`payment_methods_payment_link_total_value_${id}`]
          );
        });
      }

      return { totalPaymentLink };
    },
    getTotalPaymentCards(contract, paymentCardRows) {
      let totalPaymentCards = 0;

      if (paymentCardRows.length) {
        paymentCardRows.forEach(({ id }) => {
          totalPaymentCards += parse(
            contract.meta[`payment_methods_payment_card_total_value_${id}`]
          );
        });
      }
      return { totalPaymentCards };
    },
    getTotalCreditCards(contract, creditCardRows) {
      let totalCreditCards = 0;
      // const supplierId = this.theProductSupplier?.id ?? "";
      let paymentDirectWithSupplier = false;
      const directWithSupplier = [];

      if (creditCardRows.length) {
        creditCardRows.forEach(({ id }) => {
          const machineId =
            contract.meta[`payment_methods_credit_card_machine_id_${id}`];

          if (machineId == 14) directWithSupplier.push(machineId);

          totalCreditCards += parse(
            contract.meta[`payment_methods_credit_card_total_value_${id}`]
          );
        });
      }

      if (directWithSupplier.length > 0) paymentDirectWithSupplier = true;

      return { totalCreditCards, paymentDirectWithSupplier };
    },
    getTotalCreditCardsByLocator(
      contract,
      locator,
      locatorTravellersArr,
      creditCardRows
    ) {
      let totalCreditCardsByLocator = 0;

      console.log(
        contract.sale,
        "getTotalCreditCardsByLocator",

        locator,
        locatorTravellersArr,
        creditCardRows
      );

      if (creditCardRows.length) {
        creditCardRows.forEach(({ id }) => {
          const locatorsArr = contract.meta[
            `payment_methods_credit_card_travellers_locators_${id}`
          ]
            ? JSON.parse(
                contract.meta[
                  `payment_methods_credit_card_travellers_locators_${id}`
                ]
              )
            : [];

          if (
            contract.meta[`payment_methods_credit_card_payment_type_${id}`] ===
              "Parcial" &&
            contract.meta[`payment_methods_credit_card_machine_id_${id}`] != 14
          ) {
            if (locatorsArr.length && locatorsArr.includes(locator)) {
              totalCreditCardsByLocator += parse(
                contract.meta[`payment_methods_credit_card_total_value_${id}`]
              );
            }
          }

          if (
            contract.meta[`payment_methods_credit_card_payment_type_${id}`] ===
              "Total" &&
            contract.meta[`payment_methods_credit_card_machine_id_${id}`] != 14
          ) {
            totalCreditCardsByLocator += parse(
              contract.meta[`payment_methods_credit_card_total_value_${id}`]
            );
          }
        });
      }

      totalCreditCardsByLocator =
        totalCreditCardsByLocator / locatorTravellersArr.length;
      if (isNaN(totalCreditCardsByLocator)) totalCreditCardsByLocator = 0;

      return {
        totalCreditCardsByLocator,
      };
    },
    creditCardLocators(contract, traveller, locator, creditCardRows) {
      let locatorTraveller = {};
      if (creditCardRows.length) {
        creditCardRows.forEach(({ id }) => {
          const locatorsArr = contract.meta[
            `payment_methods_credit_card_travellers_locators_${id}`
          ]
            ? JSON.parse(
                contract.meta[
                  `payment_methods_credit_card_travellers_locators_${id}`
                ]
              )
            : [];

          if (
            contract.meta[`payment_methods_credit_card_payment_type_${id}`] ===
              "Parcial" &&
            contract.meta[`payment_methods_credit_card_machine_id_${id}`] != 14
          ) {
            if (locatorsArr.length && locatorsArr.includes(locator)) {
              locatorTraveller = traveller;
            }
          }

          if (
            contract.meta[`payment_methods_credit_card_payment_type_${id}`] ===
              "Total" &&
            contract.meta[`payment_methods_credit_card_machine_id_${id}`] != 14
          ) {
            locatorTraveller = traveller;
          }
        });
      }

      return {
        locatorTraveller,
      };
    },
    getTravellerTotals(contract, flight, traveller) {
      let travellerValue = 0;
      let travellerTaxValue = 0;
      let travellerDuTaxValue = 0; // a receber
      let travellerTebAgencyValue = 0; //a receber

      let tickerValue =
        contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_ticket_value`
        ];
      let ticketTaxValue =
        contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_ticket_tax_value`
        ];

      let duTax =
        contract.meta[`flight_${flight.id}_traveller_${traveller.id}_du_tax`];
      let tebAgency =
        contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
        ];

      if (tickerValue) {
        travellerValue = parse(tickerValue);
      } else {
        travellerValue = contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_value`
        ]
          ? parse(
              contract.meta[
                `flight_${flight.id}_traveller_${traveller.id}_value`
              ]
            )
          : 0;
      }

      if (ticketTaxValue) {
        travellerTaxValue = parse(ticketTaxValue);
      } else {
        travellerTaxValue = contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_taxes`
        ]
          ? parse(
              contract.meta[
                `flight_${flight.id}_traveller_${traveller.id}_taxes`
              ]
            )
          : 0;
      }

      if (duTax) travellerDuTaxValue = parse(duTax);
      if (tebAgency) travellerTebAgencyValue = parse(tebAgency);

      return {
        travellerValue,
        travellerTaxValue,
        travellerDuTaxValue,
        travellerTebAgencyValue,
      };
    },
    getAllContractTravellers(contract) {
      const allContractTravellers = [];

      JSON.parse(contract.meta.travellers_rows).forEach(({ id }) => {
        allContractTravellers.push({
          id,
          name: `${contract.meta[`traveller_${id}_first_name`]}
          ${contract.meta[`traveller_${id}_last_name`]}`.toUpperCase(),
        });
      });

      return { allContractTravellers };
    },
    getTotalBankTransfer(contract, paymentMethods, allContractTravellers) {
      let totalBankTransfer = 0;
      let bankTransferDividedByTravellers = 0;

      if (paymentMethods.includes("bank-transfer")) {
        totalBankTransfer = parse(
          contract.meta[`payment_methods_bank_transfer_total_value`]
        );

        bankTransferDividedByTravellers =
          totalBankTransfer / allContractTravellers.length;
      }

      return { totalBankTransfer, bankTransferDividedByTravellers };
    },
    getValuesFromDataBase(contract, flight, traveller, allContractTravellers) {
      const incomingValue =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_incoming_value`
        ];

      const toPayValue =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_to_pay_value`
        ];

      let balanceValue =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_balance_value`
        ];

      const theAuditStatus =
        contract.meta[`contract_finances_flight_${flight.id}_audit_status`] ??
        "Em aberto";

      let theInvoiceStatus =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_invoice_status`
        ] ?? "Em aberto";

      let theInvoiceGroup =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_invoice_group`
        ] ?? "";

      const locator =
        contract.meta[`flight_${flight.id}_traveller_${traveller.id}_locator`];

      const extra_fee_value =
        contract.meta[
          `flight_${flight.id}_traveller_${traveller.id}_extra_fee_value`
        ] ?? 0;

      const paid_at =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_paid_at`
        ] ?? "---";

      const payment_type =
        contract.meta[`contract_finances_flight_${flight.id}_payment_type`] ??
        "---";

      const traveller_name = this.travellerNameById(
        allContractTravellers,
        contract.meta[`flight_${flight.id}_traveller_${traveller.id}`]
      );

      const invoice_id =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_invoice_id`
        ] ?? 0;

      const event_id =
        contract.meta[
          `contract_finances_flight_${flight.id}_traveller_${traveller.id}_event_id`
        ] ?? 0;

      //if (theAuditStatus === "Realizado") theInvoiceStatus = "Pago";

      return {
        incomingValue,
        toPayValue,
        balanceValue,
        theAuditStatus,
        theInvoiceStatus,
        theInvoiceGroup,
        locator,
        extra_fee_value,
        paid_at,
        payment_type,
        traveller_name,
        invoice_id,
        event_id,
      };
    },
    getTravellerLocator(contract, flight, creditCardRows) {
      const locatorTravellersArr = [];

      flight.travellers.forEach((traveller) => {
        let { locatorTraveller } = this.creditCardLocators(
          contract,
          traveller,
          contract.meta[
            `flight_${flight.id}_traveller_${traveller.id}_locator`
          ],
          creditCardRows
        );

        if (locatorTraveller.id !== undefined)
          locatorTravellersArr.push(locatorTraveller);
      });

      return { locatorTravellersArr };
    },
    getFlightTotalValue(
      contract,
      flight,
      paymentMethods,
      paymentDirectWithSupplier
    ) {
      let flightTotalValue = 0;
      let flightTotalValueWithRule = 0;
      let tebPlusDuValue = 0;

      flight.travellers.forEach((traveller) => {
        let {
          travellerValue,
          travellerTaxValue,
          travellerDuTaxValue,
          travellerTebAgencyValue,
        } = this.getTravellerTotals(contract, flight, traveller);

        console.log(
          contract.sale,
          "getTravellerTotals",
          travellerDuTaxValue,
          travellerTebAgencyValue
        );

        tebPlusDuValue += travellerTebAgencyValue + travellerDuTaxValue;

        flightTotalValue +=
          travellerValue +
          travellerTaxValue +
          travellerDuTaxValue +
          travellerTebAgencyValue;

        if (
          !paymentDirectWithSupplier ||
          paymentMethods.includes("payment-link") ||
          paymentMethods.includes("bank-transfer")
        ) {
          travellerDuTaxValue = 0;
          travellerTebAgencyValue = 0;
        }

        flightTotalValueWithRule +=
          travellerValue +
          travellerTaxValue +
          travellerDuTaxValue +
          travellerTebAgencyValue;
      });

      return { flightTotalValue, flightTotalValueWithRule, tebPlusDuValue };
    },
    getHotelTotalIncentives(contract, hotel) {
      const incentivesRows = contract.meta[
        `contract_finances_hotel_${hotel.id}_incentives_rows`
      ]
        ? JSON.parse(
            contract.meta[`contract_finances_hotel_${hotel.id}_incentives_rows`]
          )
        : [];

      let totalIncentives = 0;

      if (incentivesRows.length) {
        incentivesRows.forEach(({ id }) => {
          const sumInTotal =
            contract.meta[
              `contract_finances_hotel_${hotel.id}_incentive_${id}_add_in_total`
            ];

          const isInvoiced =
            contract.meta[
              `contract_finances_hotel_${hotel.id}_incentive_${id}_is_invoiced`
            ] ?? true;

          if (
            contract.meta[
              `contract_finances_hotel_${hotel.id}_incentive_${id}_type`
            ] === "R$"
          ) {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_hotel_${hotel.id}_incentive_${id}_value`
                ]
              );
            }
          } else {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_hotel_${hotel.id}_incentive_${id}_percentage_value`
                ]
              );
            }
          }
        });
      }

      if (isNaN(totalIncentives) || totalIncentives == Infinity)
        totalIncentives = 0;

      return { totalIncentives };
    },
    getServiceTotalIncentives(contract, service) {
      const incentivesRows = contract.meta[
        `contract_finances_service_${service.id}_incentives_rows`
      ]
        ? JSON.parse(
            contract.meta[
              `contract_finances_service_${service.id}_incentives_rows`
            ]
          )
        : [];

      let totalIncentives = 0;

      if (incentivesRows.length) {
        incentivesRows.forEach(({ id }) => {
          const sumInTotal =
            contract.meta[
              `contract_finances_service_${service.id}_incentive_${id}_add_in_total`
            ];

          const isInvoiced =
            contract.meta[
              `contract_finances_service_${service.id}_incentive_${id}_is_invoiced`
            ] ?? true;

          if (
            contract.meta[
              `contract_finances_service_${service.id}_incentive_${id}_type`
            ] === "R$"
          ) {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_service_${service.id}_incentive_${id}_value`
                ]
              );
            }
          } else {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_service_${service.id}_incentive_${id}_percentage_value`
                ]
              );
            }
          }
        });
      }

      if (isNaN(totalIncentives) || totalIncentives == Infinity)
        totalIncentives = 0;

      return { totalIncentives };
    },
    getPackageTotalIncentives(contract) {
      const incentivesRows = contract.meta[
        `contract_finances_package_incentives_rows`
      ]
        ? JSON.parse(contract.meta[`contract_finances_package_incentives_rows`])
        : [];

      let totalIncentives = 0;

      if (incentivesRows.length) {
        incentivesRows.forEach(({ id }) => {
          const sumInTotal =
            contract.meta[
              `contract_finances_package_incentive_${id}_add_in_total`
            ];

          const isInvoiced =
            contract.meta[
              `contract_finances_package_incentive_${id}_is_invoiced`
            ] ?? true;

          if (
            contract.meta[`contract_finances_package_incentive_${id}_type`] ===
            "R$"
          ) {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[`contract_finances_package_incentive_${id}_value`]
              );
            }
          } else {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_package_incentive_${id}_percentage_value`
                ]
              );
            }
          }
        });
      }

      if (isNaN(totalIncentives) || totalIncentives == Infinity)
        totalIncentives = 0;

      return { totalIncentives };
    },
    getFlightTotalIncentives(contract, flight) {
      const incentivesRows = contract.meta[
        `contract_finances_flight_${flight.id}_incentives_rows`
      ]
        ? JSON.parse(
            contract.meta[
              `contract_finances_flight_${flight.id}_incentives_rows`
            ]
          )
        : [];

      let totalIncentives = 0;

      if (incentivesRows.length) {
        incentivesRows.forEach(({ id }) => {
          const sumInTotal =
            contract.meta[
              `contract_finances_flight_${flight.id}_incentive_${id}_add_in_total`
            ];

          const isInvoiced =
            contract.meta[
              `contract_finances_flight_${flight.id}_incentive_${id}_is_invoiced`
            ] ?? false;

          if (
            contract.meta[
              `contract_finances_flight_${flight.id}_incentive_${id}_type`
            ] === "R$"
          ) {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_flight_${flight.id}_incentive_${id}_value`
                ]
              );
            }
          } else {
            if (
              !["1", true].includes(sumInTotal) &&
              ["1", true].includes(isInvoiced)
            ) {
              totalIncentives += parse(
                contract.meta[
                  `contract_finances_flight_${flight.id}_incentive_${id}_percentage_value`
                ]
              );
            }
          }
        });
      }

      if (isNaN(totalIncentives) || totalIncentives == Infinity)
        totalIncentives = 0;

      return { totalIncentives };
    },
    getFlightTotalExtraTaxes(contract, flight) {
      const extraTaxesRows = contract.meta[
        `contract_finances_flight_${flight.id}_taxes_rows`
      ]
        ? JSON.parse(
            contract.meta[`contract_finances_flight_${flight.id}_taxes_rows`]
          )
        : [];

      let totalExtraTaxes = 0;

      if (extraTaxesRows.length) {
        extraTaxesRows.forEach(({ id }) => {
          if (
            !["1", true].includes(
              contract.meta[
                `contract_finances_flight_${flight.id}_tax_${id}_add_in_total`
              ]
            )
          ) {
            totalExtraTaxes += parse(
              contract.meta[
                `contract_finances_flight_${flight.id}_tax_${id}_value`
              ]
            );
          }
        });
      }

      return { totalExtraTaxes };
    },
    getHotelTotalExtraTaxes(contract, hotel) {
      const extraTaxesRows = contract.meta[
        `contract_finances_hotel_${hotel.id}_taxes_rows`
      ]
        ? JSON.parse(
            contract.meta[`contract_finances_hotel_${hotel.id}_taxes_rows`]
          )
        : [];

      let totalExtraTaxes = 0;

      if (extraTaxesRows.length) {
        extraTaxesRows.forEach(({ id }) => {
          if (
            !["1", true].includes(
              contract.meta[
                `contract_finances_hotel_${hotel.id}_tax_${id}_add_in_total`
              ]
            )
          ) {
            totalExtraTaxes += parse(
              contract.meta[
                `contract_finances_hotel_${hotel.id}_tax_${id}_value`
              ]
            );
          }
        });
      }

      return { totalExtraTaxes };
    },
    getServiceTotalExtraTaxes(contract, service) {
      const extraTaxesRows = contract.meta[
        `contract_finances_service_${service.id}_taxes_rows`
      ]
        ? JSON.parse(
            contract.meta[`contract_finances_service_${service.id}_taxes_rows`]
          )
        : [];

      let totalExtraTaxes = 0;

      if (extraTaxesRows.length) {
        extraTaxesRows.forEach(({ id }) => {
          if (
            !["1", true].includes(
              contract.meta[
                `contract_finances_service_${service.id}_tax_${id}_add_in_total`
              ]
            )
          ) {
            totalExtraTaxes += parse(
              contract.meta[
                `contract_finances_service_${service.id}_tax_${id}_value`
              ]
            );
          }
        });
      }

      return { totalExtraTaxes };
    },
    getPackageTotalExtraTaxes(contract) {
      const extraTaxesRows = contract.meta[
        `contract_finances_package_taxes_rows`
      ]
        ? JSON.parse(contract.meta[`contract_finances_package_taxes_rows`])
        : [];

      let totalExtraTaxes = 0;

      if (extraTaxesRows.length) {
        extraTaxesRows.forEach(({ id }) => {
          if (
            !["1", true].includes(
              contract.meta[`contract_finances_package_tax_${id}_add_in_total`]
            )
          ) {
            totalExtraTaxes += parse(
              contract.meta[`contract_finances_package_tax_${id}_value`]
            );
          }
        });
      }

      return { totalExtraTaxes };
    },
    getFlightAirports(contract, flight) {
      let flightAirports = "";

      flight.sections.forEach((section) => {
        flightAirports +=
          contract.meta[`flight_${flight.id}_section_${section.id}_origin`] +
          " ✈ " +
          contract.meta[
            `flight_${flight.id}_section_${section.id}_destination`
          ] +
          " / ";
      });

      return { flightAirports: flightAirports.slice(0, -2) };
    },
    packageEntries(contract) {
      const { totalIncentives } = this.getPackageTotalIncentives(contract);
      const { totalExtraTaxes } = this.getPackageTotalExtraTaxes(contract);
      const { allContractTravellers } = this.getAllContractTravellers(contract);

      const passengersArr = [];
      const locator = "---";
      const totalValue = parse(contract.meta[`package_total_price`]);

      const totalNetValue = parse(
        contract.meta[`contract_finances_package_net_value`]
      );
      const totalProfit = totalValue - totalNetValue;

      const toPayValue =
        contract.meta[`contract_finances_package_to_pay_value`];

      const travellersNameArr = allContractTravellers.map((traveller) => {
        return traveller.name;
      });

      const entry = {
        key: `${contract.id}-${contract.sale}-package`,
        id: contract.id,
        sale_id: contract.sale,
        release_date: contract.release_date,
        package_type: contract.package_type,
        product_type: "package",
        product_id: 1,
        product: "PACOTE COMPLETO",
        traveller_id: "",
        traveller_name: travellersNameArr.join("<br>"),
        locator,
        supplier: contract.meta[`contract_finances_package_supplier`],
        customer: contract.customer,
        company_branch: contract.company_branch,
        ticket_value: totalNetValue,
        ticket_tax_value: 0,
        extra_fee_value:
          contract.meta[`contract_finances_package_extra_fee_value`] ?? 0,
        paid_at: contract.meta[`contract_finances_package_paid_at`] ?? "---",
        payment_type:
          contract.meta[`contract_finances_package_payment_type`] ?? "---",
        audit_status:
          contract.meta[`contract_finances_package_audit_status`] ?? "---",
        invoice_status:
          contract.meta[`contract_finances_package_invoice_status`] ??
          "Em aberto",
        invoice_group:
          contract.meta[`contract_finances_package_invoice_group`] ?? "",
        invoice_id: contract.meta[`contract_finances_package_invoice_id`] ?? 0,
        event_id: contract.meta[`contract_finances_package_event_id`] ?? 0,
        incomings: totalIncentives + totalProfit,
        to_pay: toPayValue ?? totalExtraTaxes,
        balance: contract.meta[`contract_finances_package_balance_value`] ?? 0,
      };

      passengersArr.push(entry);

      return passengersArr;
    },
  },
};
